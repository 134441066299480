/* eslint-disable react/button-has-type */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import PropTypes from 'prop-types';

type Props = {
  children?: any;
  className?: string;
  color?: string;
  size?: string;
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
  ariaLabel?: string;
  stretched?: boolean;
  outline?: boolean;
  bordered?: boolean;
  disabled?: boolean;
  onClick?: Function;
  leftIcon?: any;
  rightIcon?: any;
  imgName?: string;
  skeleton?: any;
  loaderStart?: string;
  loaderEnd?: string;
  loading?: any;
  id?: string;
  dataTestId?: string;
  btnType?: string;
};

const DecaButton = ({
  children,
  className,
  color,
  size,
  type,
  ariaLabel,
  stretched,
  outline,
  bordered,
  disabled,
  onClick,
  leftIcon,
  rightIcon,
  imgName,
  skeleton,
  loaderStart,
  loaderEnd,
  loading,
  id,
  dataTestId,
}: Props) => {
  return (
    <button
      aria-label={ariaLabel}
      onClick={(event) => onClick && onClick(event)}
      className={`${className} ${color && `btn-${color}`} ${size && `btn-${size}`} ${stretched ? 'btn-stretched' : ''
        } ${bordered ? 'btn-bordered' : ''} ${outline ? 'btn-outline' : ''} ${disabled ? 'btn-disabled' : ''}  ${loading ? 'btn-loading' : ''
        } ${skeleton ? 'btn-skeleton' : ''} btn`}
      type={type}
      disabled={disabled}
      data-test-id={dataTestId}
      loader-start={loaderStart ? loaderStart || 'Loading...' : ''}
      loader-end={loaderEnd ? loaderEnd || 'Completed' : ''}
      id={id}
    >
      {leftIcon && (
        <span className="mr-4">
          <img src={leftIcon} alt={imgName} />
        </span>
      )}
      {children}
      {rightIcon && (
        <span className="float-right">
          <img src={rightIcon} alt={imgName} />
        </span>
      )}
    </button>
  );
};

DecaButton.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  'data-test-id': PropTypes.string,
  stretched: PropTypes.bool,
  bordered: PropTypes.bool,
  outline: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  leftIcon: PropTypes.bool,
  rightIcon: PropTypes.bool,
  skeleton: PropTypes.bool,
  loading: PropTypes.bool,
};

DecaButton.defaultProps = {
  className: '',
  color: 'default',
  size: 'md',
  type: 'button',
  id: '',
  'data-test-id': '',
  stretched: false,
  bordered: false,
  outline: false,
  disabled: false,
  onClick: () => { },
  leftIcon: false,
  rightIcon: false,
  skeleton: false,
  loading: false,
};

export default DecaButton;
