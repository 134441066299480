import React from 'react';
import { Desktop, Mobile } from '../CustomMediaQuery';

const ProductCardSkeleton = () => {
  return (
    <div>
      <div className="mx-auto border-[0.5px] border-solid border-grey-100 md:border-grey-200 lg:border">
        <div>
          <Desktop>
            <div className="skeleton w-full" style={{ aspectRatio: '1 / 1' }} />
          </Desktop>
          <Mobile>
            <div className="skeleton w-full" style={{ aspectRatio: '1 / 1' }} />
          </Mobile>
        </div>
        <div className="px-2 py-5">
          <div className="h-[43px] w-[84px] skeleton dark" />
          <div className="skeleton dark mt-3 h-[25px] w-10/12 md:w-40 lg:w-full" />
          <div className="skeleton dark mt-3 mr-4 h-[21px] w-[60px]" />
        </div>
      </div>
    </div>
  );
};

export default ProductCardSkeleton;
