import React from 'react';
import { TabletDesktop, Desktop, OnlyMobile } from '../../common/CustomMediaQuery';
import ProductCardSkeleton from '../../common/ProductCards/ProductCardSkeleton';
import BlogBannerSkeleton from './BlogBannerSkeleton';

const ProductScrollSkeleton = () => {
  return (
    <div>
      <div className="container pb-6 px-3.5 mx-auto">
        <TabletDesktop>
          <div className="mb-3.5 flex justify-between">
            <div className="flex items-center justify-center p-3 bg-grey-50">
              <div className="mr-3.5 skeleton dark h-4 w-[260px]" />
            </div>
            <div className="flex items-center justify-center p-3 bg-grey-50">
              <div className="skeleton dark w-[71px] h-[25px]" />
            </div>
          </div>

          <div className="flex items-center flex-nowrap">
            <div className="pr-1 mr-4 lg:w-3/12">
              <ProductCardSkeleton />
            </div>
            <div className="pr-1 mr-4 lg:w-3/12">
              <ProductCardSkeleton />
            </div>

            <div className="pr-1 mr-4 lg:w-3/12">
              <ProductCardSkeleton />
            </div>
            <Desktop>
              <div className="pr-1 mr-4 lg:w-3/12">
                <ProductCardSkeleton />
              </div>
              <div className="pr-1 mr-4 lg:w-3/12">
                <ProductCardSkeleton />
              </div>
            </Desktop>
          </div>
        </TabletDesktop>

        <OnlyMobile>
          <BlogBannerSkeleton />
        </OnlyMobile>
      </div>
    </div>
  );
};

export default ProductScrollSkeleton;
